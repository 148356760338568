import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import { StaticHero, ImageViewer } from '/src/components/common'

const MillImageOne = () => {
  return <StaticImage 
    src="../../images/merchant-bar.jpeg" 
    alt="Mill image one"
    width={520}
    height={420}
    placeholder="blurred"/>
}

const MillImageTwo = () => {
  return <StaticImage 
    src="../../images/honeycomb-pipe.jpeg" 
    alt="Mill image two"
    width={520}
    height={420}
    placeholder="blurred" />
}

const MillPage = () => {
  return (
    <Layout hero={<StaticHero title="Unanderra Mill">
      <StaticImage 
        src="../../images/merchant-bar.jpeg" 
        alt="Unanderra Mill"
        width={1420}
        height={250}
        placeholder="blurred"
        layout="fixed" />
    </StaticHero>} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col xs={10}>
          <div className="mt-4 mb-4">
            <Link className="back-to-link" to="/mills">
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to mills
            </Link>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col xs={10}>
          <Row>
            <Col xs={12}>
              <div className="title-container mb-5">
                <h1 className="title">Unanderra</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <p>Orrcon Steel is excited by the commissioning of our state of the art Unanderra mill prior to end of June 2024. This cutting-edge facility represents a significant milestone in our commitment to innovation and excellence in steel manufacturing. The Unanderra mill will be instrumental in producing our renowned StrucTube branded product, catering to the diverse needs of our customers.</p>
              <p>Equipped with the latest technology and adhering to the highest industry standards, the Unanderra mill signifies Orrcon Steel's dedication to delivering top-quality steel solutions.</p>
              <p>As we look forward to the commissioning of the Unanderra mill, Orrcon Steel remains steadfast in our mission to provide the market with exceptional steel products. Stay tuned for a new era in steel manufacturing excellence with the Unanderra mill and our acclaimed StrucTube brand.</p>
            </Col>
            <Col md={6}>
              <ImageViewer images={[
                <MillImageOne />,
                <MillImageTwo />
              ]} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mt-5">
        <Col xs={10}>
          <Row>
            <Col xs={12}>
              <div className="title-container mb-5">
                <h1 className="title">Mill Details</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <ul className="store-address-list">
                <li>
                  <label>Address</label>
                  <span>13 Marley Pl, Unanderra NSW 2526</span>
                </li>
                <li>
                  <label>Email</label>
                  <span>info@orrcon.com.au</span>
                </li>
                <li>
                  <label>Phone Number</label>
                  <span>13 STEEL (13 78 33)</span>
                </li>
                <li>
                  <label>Complaints</label>
                  <span>1800 640 252</span>
                </li>
                <li>
                  <label>Incident Response Management</label>
                  <span>
                    <a href="https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/PIRMP%20Orrcon%20Manufacturing%20Unanderra%20Public%20PDF.pdf" target="_blank">
                      NSW Pollution Incident Response
                    </a>
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default MillPage

export const Head = () => <title>Unanderra Mill | Orrcon Steel</title>
